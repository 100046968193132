<template>
  <div class="card">
    <div class="card-body">
      <h4 class="mb-2">
        Features & Download
      </h4>

      <table class="table border table-condensed table-striped table-bordered">
        <tr>
          <td class="border">
            Upload by
          </td>
          <td class="border">
            {{ file.upload_by ? 'Researcher' : 'ICREA' }}
          </td>
        </tr>
        <tr>
          <td class="border">
            Status
          </td>
          <td class="border">
            <span
              v-if="file.realStatus === 1"
              class="badge bg-light-danger"
            >Inactive</span>
            <span
              v-if="file.realStatus === 2"
              class="badge bg-light-warning"
            >Pending to revision</span> <!-- cuando la host sube un documento y está a la espera de validación por ICREA -->
            <span
              v-if="file.realStatus === 3"
              class="badge bg-light-success"
            >Active</span>
          </td>
        </tr>
        <!-- <tr>only admin
          <td class="border">
            Follow
          </td>
          <td class="border">
            <span
              v-if="file.follow"
              class="badge bg-light-success"
            >Yes</span>
            <span
              v-else
              class="badge bg-light-danger"
            >No</span>
          </td>
        </tr> -->
        <tr>
          <td class="border">
            Need feedback?
          </td>
          <td class="border">
            <span
              v-if="file.need_feedback && file.response"
              class="badge bg-light-success"
            >Response received</span>
            <span
              v-if="file.need_feedback && !file.response"
              class="badge bg-light-warning"
            >Response pending</span>
            <span
              v-if="!file.need_feedback"
              class="badge bg-light-danger"
            >No</span>
          </td>
        </tr>
        <tr>
          <td class="border">
            Deadline
          </td>
          <td
            class="border text-danger"
          >
            {{ file.deadline }}
          </td>
        </tr>
        <tr>
          <td class="border">
            Year
          </td>
          <td class="border">
            {{ file.year }}
          </td>
        </tr>
        <tr>
          <td class="border">
            Renew
          </td>
          <td class="border">
            <template v-if="file.renew_year">
              <span class="badge bg-light-success">Yes</span> {{ file.renew_year }}
            </template>
            <template v-else>
              <span class="badge bg-light-danger">No</span>
            </template>
          </td>
        </tr>
        <tr>
          <td class="border">
            Archived data
          </td>
          <td class="border">
            {{ file.archived_date }}
          </td>
        </tr>
      </table>

      <hr>

      <a
        class="btn btn-small btn-primary"
        :href="file.url"
        target="_blank"
      >Download</a>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  components: {
  },
  data() {
    return {}
  },
  computed: {
    ...mapGetters({
      fields: 'modals/tableSettingsFields',
      file: 'cae/file',
    }),
  },
  mounted() {

  },
}
</script>
