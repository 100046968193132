<template>
  <div
    v-if="fileIcreas"
    class="card"
  >
    <div class="card-body">
      <h4 class="mb-2">
        ICREA'S
      </h4>
      <template
        v-for="file in fileIcreas"
      >
        <div
          v-for="icrea in file.shareable.users"
          :key="icrea.id"
        >
          <div
            class="d-flex justify-content-left align-items-center"
          >
            <div class="avatar-wrapper">
              <div class="avatar me-50">
                <img
                  :src="icrea.avatarUrl"
                  :alt="icrea.full_name"
                  width="32"
                  height="32"
                >
              </div>
            </div>
            <div class="d-flex flex-column ps-1">
              <h6 class="user-name text-truncate mb-0">
                <a
                  href="cae-icrea.php"
                  class="text-dark"
                >{{ icrea.full_name }}</a>
              </h6>
              <small class="text-truncate text-muted">{{ icrea.workplaces.map(e => e.name).join(', ') }}</small>
            </div>
          </div>

          <hr>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  components: {
  },
  data() {
    return {}
  },
  computed: {
    ...mapGetters({
      fields: 'modals/tableSettingsFields',
      file: 'cae/file',
      filters: 'filters/filters',
      fileIcreas: 'cae/fileIcreas',
    }),
  },
  mounted() {
    this.$store.dispatch('cae/fetchDocumentIcreas', {
      document: this.file.id,
      filters: this.filters,
    })
  },
}
</script>
